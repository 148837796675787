import { AxiosInstance } from 'axios';

import { redirectToLogin } from 'store/auth/authThunk';
import { SERVICE_CONFIG } from 'constants/LocalStorage';
import { authHeaders } from 'constants/AuthHeaders';
import { Token } from '../../../enum/Token';

export function requestInterceptors(instanceAxios: AxiosInstance) {
  return instanceAxios.interceptors.request.use((config: any) => {
    const access_token = localStorage.getItem(Token.ACCESS_TOKEN);
    const refresh_token = localStorage.getItem(Token.REFRESH_TOKEN);
    const service_config_data = sessionStorage.getItem(SERVICE_CONFIG);
    const { headers = {}, url } = config;

    if (service_config_data && url !== 'logout' && url !== 'token') {
      const service_config = JSON.parse(service_config_data);

      if (service_config) {
        headers[authHeaders.account] = service_config.account;
        headers[authHeaders.service] = service_config.service;
      }
    }

    if (access_token && refresh_token) {
      headers.Authorization = `Bearer ${access_token}`;
    } else if (!refresh_token) {
      redirectToLogin();
    }

    return {
      ...config,
      headers,
    };
  });
}
