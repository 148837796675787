import {
  useState,
  MouseEvent,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  faAngleDown,
  faAngleUp,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromBracket, faSliders } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  accessSelector, avatarSelector, serviceSelector, servicesSelector, userSelector,
} from 'store/auth/authSelectors';
import { RoutePath } from 'constants/RoutePath';
import { useTypedDispatch } from 'hooks/useTypedDispatch';
import { logoutAccount } from 'store/auth/authThunk';
import ListMenuItem from 'components/layouts/HomePage/components/ListMenuItem';
import { AdminAccess } from 'enum/UserRole';

import './AccountMenu.scss';

const AccountMenu = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const user = useSelector(userSelector);
  const services = useSelector(servicesSelector);
  const access = useSelector(accessSelector);
  const serviceData = useSelector(serviceSelector);
  const avatar = useSelector(avatarSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const userData = useMemo(() => {
    if (user) {
      const name = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
      return {
        name,
        email: user.email,
        placeholder: name?.[0] || user?.email?.[0],
      };
    }
    return {};
  }, [user]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = (event: MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    handleClose();
  };

  const handleLogoutClick = () => {
    dispatch(logoutAccount());
    handleClose();
  };

  const handlePreferencesClick = () => {
    console.log('/preferences');
    handleClose();
  };

  const menu = useMemo(() => [
    {
      label: t('preferences'),
      onClick: handlePreferencesClick,
      icon: <FontAwesomeIcon icon={faSliders} />,
    },
    {
      label: t('logout'),
      onClick: handleLogoutClick,
      icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
    },
  ], []);

  const userRole = useMemo(() => {
    if (!serviceData) {
      return '';
    }

    if (access === AdminAccess.ADMIN_LITE) {
      return t('maintainer');
    }

    if (access === AdminAccess.ADMIN) {
      return t('admin');
    }

    return t('member');
  }, [access, serviceData]);

  const servicesList = useMemo(() => (
    services?.map(({
      shortName,
      name,
      logo,
      access,
      accountName,
      accountShortName,
    }) => (
      <ListMenuItem
        key={shortName}
        shortName={shortName}
        name={name}
        logo={logo}
        access={access}
        accountName={accountName}
        accountShortName={accountShortName}
        onClick={handleClose}
      />
    )) || []
  ), [services]);

  return (
    <Box className="account-menu">
      <Box>
        <IconButton
          onClick={handleClick}
          size="small"
          className="account-menu__logo"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar src={avatar || ''}>
            {userData?.placeholder}
          </Avatar>
          <Box className="name-wrapper" data-testid="user-role-testid">
            {userData?.name || userData?.email}
            {userRole && <span className="role">{userRole}</span>}
          </Box>
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="account-menu__menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box
          className="account-menu__menu-header"
          component={Link}
          to={RoutePath.ACCOUNT}
          onClick={handleClose}
        >
          <Avatar src={avatar || ''}>
            {userData?.placeholder}
          </Avatar>
          <Box className="account-menu__menu-header-info">
            <Typography className="name">{userData?.name}</Typography>
            <Typography className="email">{userData?.email}</Typography>
          </Box>
          <FontAwesomeIcon
            icon={faXmark}
            className="close-icon"
            onClick={handleIconClick}
          />
        </Box>
        <Box
          className="account-menu__menu-services"
        >
          {servicesList?.length > 0 && (
          <>
            <Typography className="title">{t('services')}</Typography>
            <Box className="account-menu__menu-services-wrapper">
              {servicesList}
            </Box>
          </>
          )}
          <Box
            className="account-menu__menu-services-manage"
            component={Link}
            to={RoutePath.HOME}
            onClick={handleClose}
          >
            {t('manageServices')}
          </Box>
        </Box>
        <Box className="account-menu__menu-links">
          {menu.map((item) => (
            <MenuItem key={item.label} onClick={item.onClick} className="account-menu__menu-links-item">
              {item.icon}
              <Typography className="link">{item.label}</Typography>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default AccountMenu;
