export enum UserRole {
  USER = 'USER',
  SUPER_USER= 'SUPER_USER',
}

export const ADMIN_POSTFIX = 'ADMIN';
export const ADMIN_LITE_POSTFIX = 'ADMIN_LITE';

export enum AdminAccess {
  ADMIN = 'ADMIN',
  ADMIN_LITE = 'ADMIN_LITE'
}
