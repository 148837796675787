import { ADMIN_LITE_POSTFIX, AdminAccess, ADMIN_POSTFIX } from 'enum/UserRole';

export const getAccessByRole = (role?: string | null) => {
  if (role?.includes(ADMIN_LITE_POSTFIX)) {
    return AdminAccess.ADMIN_LITE;
  }

  if (role?.includes(ADMIN_POSTFIX)) {
    return AdminAccess.ADMIN;
  }

  return null;
};
