import { unionBy } from 'lodash';

import { ThunkActionType } from '../store';
import {
  setIsPlugInsLoading,
  setPlugIns,
  setIsPlugInStoreLoading,
  setPlugInStore,
  setTriggerPlugInStore,
  setTriggerPlugIns,
  setActivePlugIn,
  setIsShowPlugInModal,
  setIsShowUploadPlugInModal,
  setIsPlugInsUpdating,
  setTotalCount,
  setPlugIn,
  setPlugInStoreTotalCount,
  setIsEndpointsLoading,
  setEndpoints,
} from './pluginReducer';
import { handleApiError } from '../app/appThunk';
import { IPlugInsRequest, IPlugInStoreRequest } from '../../interfaces/IPlugIn';
import {
  deletePlugInInstance,
  getEndpointsApi,
  getPlugInApi,
  getPlugInByIdApi,
  getPlugInsApi,
  getPlugInStoreApi,
  removePlugIn,
  startPlugInApi,
  startPlugInInstanceApi,
  stopPlugInInstance,
  updateInstanceSettings,
  uploadPlugInApi,
} from '../../services/api/plugInApi';

export const getPlugIns = (data: IPlugInsRequest, isSelect?: boolean): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsPlugInsLoading(true));
      const res = await getPlugInsApi(data);

      if (isSelect) {
        const { plugIns } = getState().plugInReducer;

        dispatch(setPlugIns(unionBy([
          ...(plugIns || []),
          ...res.content,
        ], '_id')));
      } else {
        dispatch(setPlugIns(res.content));
      }
      dispatch(setTotalCount(res.totalCount));
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInsLoading(false));
    }
  };
};

export const getPlugInById = (plugInId: string): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsLoading(true));
      const res = await getPlugInByIdApi(plugInId);
      dispatch(setPlugIn(res));
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInsLoading(false));
    }
  };
};

export const getPlugInStore = (data: IPlugInStoreRequest): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInStoreLoading(true));
      dispatch(setTriggerPlugIns());
      const res = await getPlugInStoreApi(data);
      dispatch(setPlugInStore(res.content));
      dispatch(setPlugInStoreTotalCount(res.totalCount));
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInStoreLoading(false));
    }
  };
};

export const getPlugInData = (name: string, version: string): ThunkActionType => {
  return async (dispatch) => {
    try {
      const res:any = await getPlugInApi(name, version);
      dispatch(setActivePlugIn(res));
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};

export const deletePlugIn = (name: string, version: string): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInStoreLoading(true));
      await removePlugIn(name, version);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setTriggerPlugInStore());
      dispatch(setIsShowPlugInModal({ isActive: false, _id: '' }));
      dispatch(setIsPlugInStoreLoading(false));
    }
  };
};

export const startPlugIn = (data: any, name: string, version: string):
    ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsUpdating(true));
      await startPlugInApi(data, name, version);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInsUpdating(false));
      dispatch(setTriggerPlugIns());
    }
  };
};

export const updatePlugInSettings = (name: string, version: string, _id: string, data: any):
  ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsUpdating(true));
      await updateInstanceSettings(name, version, _id, data);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInsUpdating(false));
      dispatch(setTriggerPlugIns());
    }
  };
};

export const startPlugInInstance = (name: string, version: string, _id: string):
    ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsUpdating(true));
      await startPlugInInstanceApi(name, version, _id);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsPlugInsUpdating(false));
      dispatch(setTriggerPlugIns());
    }
  };
};

export const stopPlugIn = (name: string, version: string, _id: string):
    ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsUpdating(true));
      await stopPlugInInstance(name, version, _id);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setTriggerPlugIns());
      dispatch(setIsPlugInsUpdating(false));
    }
  };
};

export const removePlugInInstance = (name: string, version: string, _id: string):
    ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInsUpdating(true));
      await deletePlugInInstance(name, version, _id);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setTriggerPlugIns());
      dispatch(setIsPlugInsUpdating(false));
    }
  };
};

export const uploadPlugIn = (plugIn: FormData):
    ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsPlugInStoreLoading(true));
      await uploadPlugInApi(plugIn);
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsShowUploadPlugInModal(false));
      dispatch(setTriggerPlugInStore());
      dispatch(setIsPlugInStoreLoading(false));
    }
  };
};

export const getEndpoints = (plugInId: string, plugInInstanceId: string):
  ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsEndpointsLoading(true));
      const res = await getEndpointsApi(plugInId, plugInInstanceId);
      dispatch(setEndpoints(res));
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsEndpointsLoading(false));
    }
  };
};
