/* eslint no-underscore-dangle: 0 */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTableList,
  faList,
  faFile,
  faPuzzlePieceSimple,
  faArrowRightArrowLeft,
  faBolt,
  faPowerOff,
  faNetworkWired,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import {
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons';

import { RoutePath } from 'constants/RoutePath';
import { isNavDisabledSelector } from 'store/app/appSelectors';
import { IAccount } from 'interfaces/IUser';
import { AdminAccess } from 'enum/UserRole';
import { favoritesViewsSelector } from '../../../store/views/viewsSelectors';
import { accessSelector, accountSelector, serviceSelector } from '../../../store/auth/authSelectors';

const pagesLinks = () => {
  const { t } = useTranslation();
  const isNavDisabled = useSelector(isNavDisabledSelector);
  const account = useSelector(accountSelector);
  const service = useSelector(serviceSelector);
  const favoritesViews = useSelector(favoritesViewsSelector);
  const access = useSelector(accessSelector);

  const accountId = (account as IAccount)?.shortName;
  const serviceId = service?.shortName;

  const viewsLinks = useMemo(() => (
    favoritesViews.map((favoritesView) => {
      return {
        path: `${accountId}/${serviceId}${RoutePath.VIEW}/${favoritesView._id}`,
        icon: <FontAwesomeIcon icon={faList} />,
        name: favoritesView.name,
        key: `view-${favoritesView._id}`,
      };
    })
  ), [favoritesViews, accountId, serviceId]);

  const userLinks = useMemo(() => ([
    {
      icon: <FontAwesomeIcon icon={faTableList} />,
      name: t('translation:links.views'),
      key: 'userViews',
      children: [
        {
          path: `${accountId}/${serviceId}${RoutePath.USER_VIEWS}`,
          icon: <FontAwesomeIcon icon={faList} />,
          name: t('translation:links.allViews'),
          key: 'allViews',
        }, ...viewsLinks,
      ],
    },
  ]), [accountId, serviceId, viewsLinks]);

  const adminLiteLinks = useMemo(() => ([
    ...userLinks,
    {
      icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
      name: t('translation:links.setup'),
      key: 'setUp',
      children: [
        {
          path: `${accountId}/${serviceId}${RoutePath.VIEWS}`,
          icon: <FontAwesomeIcon icon={faTableList} />,
          name: t('translation:links.views'),
          key: 'views',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.PLUG_INS}`,
          icon: <FontAwesomeIcon icon={faPuzzlePieceSimple} />,
          name: t('translation:links.plugIns'),
          key: 'plugIns',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.MEMBERS}`,
          icon: <FontAwesomeIcon icon={faUsers} />,
          name: t('translation:links.members'),
          key: 'members',
        },
      ],
    },
  ]), [accountId, serviceId, userLinks]);

  const adminLinks = useMemo(() => ([
    ...userLinks,
    {
      icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
      name: t('translation:links.setup'),
      key: 'setUp',
      children: [
        {
          path: `${accountId}/${serviceId}${RoutePath.VIEWS}`,
          icon: <FontAwesomeIcon icon={faTableList} />,
          name: t('translation:links.views'),
          key: 'views',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.OBJECT_MANAGER}`,
          icon: <FontAwesomeIcon icon={faFile} />,
          name: t('translation:links.objects'),
          key: 'objects',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.TRANSFORMERS}`,
          icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} />,
          name: t('translation:links.transformers'),
          key: 'transformers',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.TRIGGERS}`,
          icon: <FontAwesomeIcon icon={faBolt} />,
          name: t('translation:links.triggers'),
          key: 'triggers',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.ACTIONS}`,
          icon: <FontAwesomeIcon icon={faPowerOff} />,
          name: t('translation:links.actions'),
          key: 'actions',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.FLOWS}`,
          icon: <FontAwesomeIcon icon={faNetworkWired} />,
          name: t('translation:links.flows'),
          key: 'flows',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.PLUG_INS}`,
          icon: <FontAwesomeIcon icon={faPuzzlePieceSimple} />,
          name: t('translation:links.plugIns'),
          key: 'plugIns',
        },
        {
          path: `${accountId}/${serviceId}${RoutePath.MEMBERS}`,
          icon: <FontAwesomeIcon icon={faUsers} />,
          name: t('translation:links.members'),
          key: 'members',
        },
      ],
    },
  ]), [accountId, serviceId, userLinks]);

  if (isNavDisabled) {
    return [];
  }

  if (access === AdminAccess.ADMIN_LITE) {
    return adminLiteLinks;
  }

  if (access === AdminAccess.ADMIN) {
    return adminLinks;
  }

  return userLinks;
};

export default pagesLinks;
