import { FC, useCallback, useMemo } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { RoutePath } from 'constants/RoutePath';
import { useSelector } from 'react-redux';
import { accountSelector, serviceSelector } from 'store/auth/authSelectors';

import './Breadcrumb.scss';

export interface IBreadCrumbItem {
  label: string;
  path?: RoutePath | string;
  hidden?: boolean;
}

interface IComponentProps {
  items: IBreadCrumbItem[];
  hideNavToPev?: boolean;
}

const Breadcrumb: FC<IComponentProps> = ({
  items,
  hideNavToPev = false,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const account = useSelector(accountSelector);
  const service = useSelector(serviceSelector);

  const prevValue = useMemo(() => {
    const index = items.findLastIndex((item) => !item.path);
    return index > 0 ? index - 1 : index;
  }, [pathname, items]);

  const navigateToPrev = useCallback(() => {
    const path = items[prevValue]?.path;
    if (path) {
      navigate(`${account?.shortName}/${service?.shortName}${path}`);
    }
  }, [items, prevValue, account, service]);

  return (
    <>
      {!hideNavToPev && (
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="breadcrumb__back-btn"
        onClick={navigateToPrev}
      />
      )}
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
        {items.map((item) => (
          !item.hidden && (item.path ? (
            <Link
              key={item.path}
              to={`${account?.shortName}/${service?.shortName}${item.path}`}
              className="breadcrumb__item"
            >
              {item.label}
            </Link>
          ) : (
            <Typography key={item.label} className="breadcrumb__item selected">
              {item.label}
            </Typography>
          )
          )))}
      </Breadcrumbs>
    </>
  );
};

export default Breadcrumb;
