import { Translation } from 'components/shared/Translation/Translation';
import { AdminAccess } from 'enum/UserRole';

export const formatServiceRole = (role?: AdminAccess | null) => {
  if (role === AdminAccess.ADMIN) {
    return <Translation value="admin" />;
  }

  if (role === AdminAccess.ADMIN_LITE) {
    return <Translation value="maintainer" />;
  }
  return <Translation value="member" />;
};
