import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  value: string;
  ns?: string | string[];
}

export const Translation:FC<IComponentProps> = ({ value, ns }) => {
  const { t } = useTranslation(ns);

  return <>{t(value)}</>;
};
