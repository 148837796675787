import { Token } from 'enum/Token';
import { isProduction } from 'utils/isProd';
import { IUser } from 'interfaces/IUser';
import { redirectToLogin } from 'store/auth/authThunk';
import authApiInstance from './instances/authApiInstance';
import apiInstance from './instances/apiInstances';

const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT || '';
export const LOGIN_URL = `${isProduction() ? process.env.REACT_APP_VAULT : 'http://localhost:8443'}/login?redirect_url=`;
export const VAULT_URL = isProduction() ? process.env.REACT_APP_VAULT : 'http://localhost:5000';
export const VAULT_API_URL = `${VAULT_URL}/api/vault`;

export const refreshTokensApi = async (refreshToken: string) => {
  const body = new URLSearchParams();

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('grant_type', 'refresh_token');
  body.append('refresh_token', refreshToken);

  const res = await authApiInstance.post('token', body);

  return res;
};

export const updateTokens = async () => {
  const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

  if (refreshToken) {
    try {
      const res = await refreshTokensApi(refreshToken);

      localStorage.setItem(Token.ACCESS_TOKEN, res.data.access_token);
      localStorage.setItem(Token.REFRESH_TOKEN, res.data.refresh_token);
    } catch (err: any) {
      localStorage.removeItem(Token.ACCESS_TOKEN);
      localStorage.removeItem(Token.REFRESH_TOKEN);
      redirectToLogin();
    }
  }
};

export const validateTokenApi = async (
  serviceId: string,
  accountId: string,
) => {
  const res = await apiInstance.post(`${VAULT_API_URL}/auth/validate_token/${accountId}/${serviceId}`, {
    baseURL: '',
  });

  return res;
};

export const getLoggedUserApi = async (): Promise<IUser> => {
  const res = await apiInstance.get(`${VAULT_API_URL}/auth/me`, {
    baseURL: '',
  });

  return res.data;
};

export const logoutApi = async () => {
  const body = new URLSearchParams();

  const refreshToken = localStorage.getItem(Token.REFRESH_TOKEN);

  body.append('client_id', KEYCLOAK_CLIENT);
  body.append('refresh_token', refreshToken || '');

  const res = await authApiInstance.post('logout', body);

  return res;
};
