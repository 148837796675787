import apiInstance from 'services/api/instances/apiInstances';

export const toDataURL = (url: string, callback: any) => {
  apiInstance.get(url, {
    responseType: 'blob',
    baseURL: '',
  }).then((response) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(response.data);
  }).catch((err) => {
    if (err.status === 500 || err.status === 403) {
      callback('');
    }
  });
};
