import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license';

import { store } from './store/store';
import ProviderWrapper from './components/shared/ProviderWrapper/ProviderWrapper';
import App from './App';
import './services/i18n';

import './index.scss';

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE_KEY || '');

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ProviderWrapper store={store}>
    <App />
  </ProviderWrapper>,
);
