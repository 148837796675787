import { lazy } from 'react';

import { AdminAccess } from 'enum/UserRole';
import { RoutePath } from '../../../constants/RoutePath';
import { ICustomRouterConfig } from '../../../interfaces/IRouterConfig';

const HomePage = lazy(() => import('../HomePage/HomePage'));
const BaseRoute = lazy(() => import('../BaseRoute/BaseRoute'));
const BaseRouteRedirect = lazy(() => import('../BaseRoute/BaseRouteRedirect'));
const ErrorPage = lazy(() => import('../ErrorPage/ErrorPage'));
const NotFoundPage = lazy(() => import('../NotFoundPage/NotFoundPage'));
const ObjectManagerPage = lazy(() => import('../ObjectManagerPage/ObjectManagerPage'));
const Transformers = lazy(() => import('../Transformers/Transformers'));
const AddTransformer = lazy(() => import('../Transformers/AddTransformer/AddTransformer'));
const EditTransformer = lazy(() => import('../Transformers/EditTransformer/EditTransformer'));
const DuplicateTransformerPage = lazy(() => import('../Transformers/DuplicateTransformer/DuplicateTransformer'));
const PlugInsPage = lazy(() => import('../PlugIns/PlugInsPage/PlugInsPage'));
const AddPlugInsPage = lazy(() => import('../PlugIns/AddPlugInsPage/AddPlugInsPage'));
const AddObjectPage = lazy(() => import('../ObjectManagerPage/pages/AddObjectPage'));
const EditObjectPage = lazy(() => import('../ObjectManagerPage/pages/EditObjectPage'));
const DuplicateObjectPage = lazy(() => import('../ObjectManagerPage/pages/DuplicateObjectPage'));
const ViewsPage = lazy(() => import('../ViewsPage/ViewsPage'));
const InstallPlugInPage = lazy(() => import('../PlugIns/InstallPlugInPage/InstallPlugInPage'));
const AddViewsPage = lazy(() => import('../ViewsPage/pages/AddViewPage/AddViewPage'));
const EditViewsPage = lazy(() => import('../ViewsPage/pages/EditViewPage/EditViewPage'));
const DuplicateViewPage = lazy(() => import('../ViewsPage/pages/DuplicateViewPage/DuplicateViewPage'));
const Triggers = lazy(() => import('../Triggers/Triggers'));
const AddTriggerPage = lazy(() => import('../Triggers/pages/AddTriggerPage'));
const EditTriggerPage = lazy(() => import('../Triggers/pages/EditTriggerPage'));
const ViewsUserPage = lazy(() => import('../ViewsUserPage/ViewsUserPage'));
const ViewPage = lazy(() => import('../ViewsUserPage/pages/ViewPage/ViewPage'));
const EditViewPage = lazy(() => import('../ViewsUserPage/pages/EditViewPage/EditViewPage'));
const DetailsViewPage = lazy(() => import('../ViewsUserPage/pages/DetailsViewPage/DetailsViewPage'));
const AddEntityPage = lazy(() => import('../ViewsUserPage/pages/AddEntityPage/AddEntityPage'));
const ActionPage = lazy(() => import('../Actions/Actions'));
const AddActionPage = lazy(() => import('../Actions/pages/AddActionPage'));
const EditActionPage = lazy(() => import('../Actions/pages/EditActionPage'));
const Flows = lazy(() => import('../Flows/Flows'));
const AddFlow = lazy(() => import('../Flows/AddFlow/AddFlow'));
const EditFlow = lazy(() => import('../Flows/EditFlow/EditFlow'));
const MembersPage = lazy(() => import('../UsersPage/UsersPage'));

export const routerConfig: ICustomRouterConfig[] = [
  {
    path: RoutePath.HOME,
    element: <HomePage />,
  },
  {
    path: RoutePath.BASE_ROUTE,
    element: <BaseRoute />,
    children: [
      {
        path: RoutePath.BASE_ROUTE,
        element: <BaseRouteRedirect />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.OBJECT_MANAGER}`,
        element: <ObjectManagerPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.TRANSFORMERS}`,
        element: <Transformers />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_TRANSFORMER}`,
        element: <AddTransformer />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_TRANSFORMER}/:id`,
        element: <EditTransformer />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.DUPLICATE_TRANSFORMER}/:id`,
        element: <DuplicateTransformerPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.PLUG_INS}`,
        element: <PlugInsPage />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.PLUG_INS_STORE}`,
        element: <AddPlugInsPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.PLUG_INS}/:name/:version`,
        element: <InstallPlugInPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.PLUG_INS}/:name/:version/:id`,
        element: <InstallPlugInPage />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_OBJECT}`,
        element: <AddObjectPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_OBJECT}/:id`,
        element: <EditObjectPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.DUPLICATE_OBJECT}/:id`,
        element: <DuplicateObjectPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEWS}`,
        element: <ViewsPage />,
        adminAccess: [AdminAccess.ADMIN_LITE, AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_VIEW}`,
        element: <AddViewsPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_VIEW}/:id`,
        element: <EditViewsPage />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.DUPLICATE_VIEW}/:id`,
        element: <DuplicateViewPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.TRIGGERS}`,
        element: <Triggers />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_TRIGGER}`,
        element: <AddTriggerPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_TRIGGER}/:id`,
        element: <EditTriggerPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.USER_VIEWS}`,
        element: <ViewsUserPage />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_USER_VIEW}/:id`,
        element: <EditViewsPage user />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.DUPLICATE_USER_VIEW}/:id`,
        element: <DuplicateViewPage user />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEW}/:id`,
        element: <ViewPage />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEW}/:viewId${RoutePath.VIEW_EDIT}/:id/:entityId/:detailsId`,
        element: <EditViewPage />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEW}/:viewId${RoutePath.VIEW_EDIT}/:id/:entityId`,
        element: <EditViewPage />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEW}/:viewId${RoutePath.VIEW_DETAILS}/:id/:entityId`,
        element: <DetailsViewPage />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}${RoutePath.USER_VIEWS}${RoutePath.VIEW_DETAILS}/:objectType/:entityId`,
        element: <DetailsViewPage noView />,
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.VIEW}/:viewId${RoutePath.ADD_ENTITY}`,
        element: <AddEntityPage />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ACTIONS}`,
        element: <ActionPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_ACTION}`,
        element: <AddActionPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_ACTION}/:id`,
        element: <EditActionPage />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.FLOWS}`,
        element: <Flows />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.ADD_FLOW}`,
        element: <AddFlow />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.EDIT_FLOW}/:id`,
        element: <EditFlow />,
        adminAccess: [AdminAccess.ADMIN],
      },
      {
        path: `${RoutePath.BASE_ROUTE}/${RoutePath.MEMBERS}`,
        element: <MembersPage />,
        adminAccess: [AdminAccess.ADMIN, AdminAccess.ADMIN_LITE],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: RoutePath.ERROR,
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
