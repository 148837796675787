import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroup } from 'interfaces/IGroup';
import { IUser } from 'interfaces/IUser';

export interface IUsersState {
  users: IUser[] | null;
  isFetchingUsers: boolean;
  triggerUsers: object;
  usersTotalCount: number | null;
  groups: IGroup[] | null;
  isFetchingGroups: boolean;
  triggerGroups: object;
  groupsTotalCount: number | null;
}

export const initialState: IUsersState = {
  users: null,
  isFetchingUsers: false,
  triggerUsers: {},
  usersTotalCount: null,
  groups: null,
  triggerGroups: {},
  isFetchingGroups: false,
  groupsTotalCount: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<IUser[] | null>) {
      state.users = action.payload;
    },
    setIsFetchingUsers(state, action: PayloadAction<boolean>) {
      state.isFetchingUsers = action.payload;
    },
    setUsersTotalCount(state, action: PayloadAction<number | null>) {
      state.usersTotalCount = action.payload;
    },
    setTriggerUsers(state) {
      state.triggerUsers = {};
    },
    setGroups(state, action: PayloadAction<IGroup[] | null>) {
      state.groups = action.payload;
    },
    setIsFetchingGroups(state, action: PayloadAction<boolean>) {
      state.isFetchingGroups = action.payload;
    },
    setGroupsTotalCount(state, action: PayloadAction<number | null>) {
      state.groupsTotalCount = action.payload;
    },
    setTriggerGroups(state) {
      state.triggerGroups = {};
    },
    resetUserState: () => initialState,
  },
});

export const {
  setUsers,
  setIsFetchingUsers,
  setUsersTotalCount,
  setTriggerUsers,
  setGroups,
  setIsFetchingGroups,
  setGroupsTotalCount,
  setTriggerGroups,
  resetUserState,
} = usersSlice.actions;

export default usersSlice.reducer;
